import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Generator from "../components/generator"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"


const IndexPage = () => {
  return (
    <Layout>
      <p>
        Bienvenue sur <em>Atlantide Story Builder</em>, un outil conçu pour t'aider à débuter dans la création d'un jeu géolocalisé. 
        Le déroulé est assez rapide et te permettra de stimuler ta créativité (et peut être lutter contre le syndrome de la feuille blanche). Il t'aidera aussi à structurer ton approche pour la rendre plus simple et accessible.
        <br/>
        <br/>
        Avec cet outil, nous allons uniquement nous concentrer sur la partie "conception", c'est à dire : l'histoire et la construction narrative du jeu !
      </p>

      <div style={{textAlign:"center", marginTop:20}}>
        <Link to="/pitchGenerator" className="btn">
          Commencer
        </Link>
      </div>
      {/**<Generator />**/}


      <div id="right-part">
        <img src={require("../images/logo.png")} id="logo"/>
      </div>
    </Layout>
  )
}

export default IndexPage
